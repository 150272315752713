import WaitListModal from "../../components/modal/WaitListModal"
import { Grid, Heading, LinkTo, Para, Container, List } from "./HomeComponents"
import data from "./data.json"
import basedIn from "../../assets/based-in.png"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import basedInSmall from "../../assets/iceland-small.png"
import DiscordSmall from "../../components/svg/DiscordSmall"
import DiscordLarge from "../../components/svg/DiscordLarge"
import { SEO } from "../../components/meta/SEO"
import meta from "../../components/meta/meta.json"
import {
  AndroidIcon,
  AppleIcon,
  WebsiteIcon,
} from "../../components/svg/HeroIcons"
import heroImage from "../../assets/hero-image.png"
import { SonataLogoHash } from "../../components/svg/SonataLogo"
import VerifiedNetworkIcon from "../../components/svg/VerifiedNetworkIcon"
import noCensorship from "../../assets/no-censorship.png"
import ChannelIcon from "../../components/svg/ChannelIcon"
import AnnouncementIcon from "../../components/svg/AnnouncementIcon"
import FlagIcon from "../../components/svg/FlagIcon"
import ThreadIcon from "../../components/svg/ThreadIcon"
import AppealIcon from "../../components/svg/AppealIcon"
import DraftIcon from "../../components/svg/DraftIcon"
import LinkPreviewIcon from "../../components/svg/LinkPreviewIcon"
import NoteHistory from "../../components/svg/NoteHistory"
import FederatedIcon from "../../components/svg/FederatedIcon"
import expandingVerifications from "../../assets/expanding-verifications.png"
import AIIcon from "../../components/svg/AIIcon"
import joinWaitlist from "../../assets/join-waitlist.png"

const discordLink = process.env.REACT_APP_DISCORD_LINK
const links = {
  "amplification-policy": "/policies/amplification-policy",
  "prohibited-content-policy": "/policies/prohibited-content-policy",
  roadmap: "/roadmap",
  whitepaper: "/whitepaper",
  about: "/about",
  "third-generation": "/third-generation-social-media",
  contact: "/contact-us",
}

function Home() {
  const screenWidth = useWindowWidth()

  return (
    <>
      <SEO title={meta?.home?.title} description={meta?.home?.description} />
      <div className="outer-container">
        <section className="hero-section">
          <Container>
            <Grid cssClass="hero-section__inner" dataAttr="2/1">
              <div className="hero-section__intro">
                <SonataLogoHash
                  width={screenWidth <= 1080 ? 44 : null}
                  height={screenWidth <= 1080 ? 44 : null}
                />
                <div className="hero-section__intro__lower">
                  <div className="hero-section__intro__heading">
                    {data?.homePage?.hero?.heading}
                  </div>
                  <div
                    className="hero-section__intro__sub-heading"
                    dangerouslySetInnerHTML={{
                      __html: data?.homePage?.hero?.["sub-heading"],
                    }}
                  />
                  {screenWidth > 1080 && (
                    <>
                      <div className="hero-section__intro__icons">
                        <div>
                          <AndroidIcon
                            width={screenWidth <= 1080 ? 26 : 40}
                            height={screenWidth <= 1080 ? 26 : 40}
                          />
                        </div>
                        <div>
                          <AppleIcon
                            width={screenWidth <= 1080 ? 21 : 34}
                            height={screenWidth <= 1080 ? 26 : 40}
                          />
                        </div>
                        <div>
                          <WebsiteIcon
                            width={screenWidth <= 1080 ? 26 : 40}
                            height={screenWidth <= 1080 ? 26 : 40}
                          />
                        </div>
                      </div>
                      <div className="hero-section__intro__invite">
                        {data?.homePage?.hero?.invite}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <img src={heroImage} alt="hero" />
              {screenWidth <= 1080 && (
                <div className="hero-section__intro__lower">
                  <div className="hero-section__intro__icons">
                    <div>
                      <AndroidIcon
                        width={screenWidth <= 1080 ? 26 : 40}
                        height={screenWidth <= 1080 ? 26 : 40}
                      />
                    </div>
                    <div>
                      <AppleIcon
                        width={screenWidth <= 1080 ? 21 : 34}
                        height={screenWidth <= 1080 ? 26 : 40}
                      />
                    </div>
                    <div>
                      <WebsiteIcon
                        width={screenWidth <= 1080 ? 26 : 40}
                        height={screenWidth <= 1080 ? 26 : 40}
                      />
                    </div>
                  </div>
                  <div className="hero-section__intro__invite">
                    {data?.homePage?.hero?.invite}
                  </div>
                </div>
              )}
            </Grid>
          </Container>
        </section>

        <section className="acronym-banner">
          <Container>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  screenWidth <= 1080
                    ? data?.["acronym-banner"]?.["para-mbl"]
                    : data?.["acronym-banner"]?.para,
              }}
            />
          </Container>
        </section>

        <section className="section-lavender">
          <Container>
            <Grid cssClass="section-lavender__inner" dataAttr="3/1">
              <div>
                <Heading
                  heading={data?.["verified-social-networking"]?.heading}
                />
                <Para para={data?.["verified-social-networking"]?.para1} />
                <Para para={data?.["verified-social-networking"]?.para2} />
              </div>
              <VerifiedNetworkIcon />
            </Grid>
          </Container>
        </section>

        <section className="section-purple">
          <Container>
            <Grid cssClass="section-purple__inner" dataAttr="3/1">
              <div>
                <Heading heading={data?.["no-censorship"]?.heading} />
                <Para para={data?.["no-censorship"]?.para1} />
                <Para para={data?.["no-censorship"]?.para2} dataAttr={"bold"} />
                <List text={data?.["no-censorship"]?.list} />
                <Para para={data?.["no-censorship"]?.para3} />
                <Para
                  para={data?.["no-censorship"]?.para4}
                  link={{
                    path: links?.["amplification-policy"],
                    text: data?.["link-texts"]?.["amplification-policy"],
                  }}
                />
                <Para
                  para={data?.["no-censorship"]?.para5}
                  link={{
                    path: links?.["prohibited-content-policy"],
                    text: data?.["link-texts"]?.["prohibited-content-policy"],
                  }}
                />
              </div>
              <img src={noCensorship} alt="no censorship" />
            </Grid>
          </Container>
        </section>

        <section className="section-white">
          <Container>
            <Grid cssClass="section-white__inner" dataAttr="auto">
              <div>
                <Heading heading={data?.["innovation"]?.heading} />
                <Para para={data?.["innovation"]?.para1} />
                <Para para={data?.["innovation"]?.para2} dataAttr={"bold"} />
                <div>
                  <Para
                    para={data?.["innovation"]?.channel}
                    dataAttr={"styled"}
                    icon={<ChannelIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.announcements}
                    dataAttr={"styled"}
                    icon={<AnnouncementIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.flags}
                    dataAttr={"styled"}
                    icon={<FlagIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.thread}
                    dataAttr={"styled"}
                    icon={<ThreadIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.appeals}
                    dataAttr={"styled"}
                    icon={<AppealIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.drafts}
                    dataAttr={"styled"}
                    icon={<DraftIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.links}
                    dataAttr={"styled"}
                    icon={<LinkPreviewIcon />}
                  />
                  <Para
                    para={data?.["innovation"]?.history}
                    dataAttr={"styled"}
                    icon={<NoteHistory />}
                  />
                </div>
              </div>
            </Grid>
          </Container>
        </section>

        <section className="section-lavender">
          <Container>
            <Grid cssClass="section-lavender__inner" dataAttr="3/1">
              <div>
                <Heading heading={data?.["federated"]?.heading} />
                <Para para={data?.["federated"]?.para1} />
                <Para para={data?.["federated"]?.para2} />
                <Para para={data?.["federated"]?.para3} />
              </div>
              <FederatedIcon />
            </Grid>
          </Container>
        </section>

        <section className="section-purple">
          <Container>
            <Grid
              cssClass="section-purple__inner expanding-verifications"
              dataAttr="3/1"
            >
              <div>
                <Heading heading={data?.["expanding-verifications"]?.heading} />
                <Para para={data?.["expanding-verifications"]?.para1} />
                <Para para={data?.["expanding-verifications"]?.para2} />
                <LinkTo
                  link={{
                    path: links?.["roadmap"],
                    text: data?.["link-texts"]?.roadmap,
                  }}
                />
              </div>
              <img src={expandingVerifications} alt="expanding verifications" />
            </Grid>
          </Container>
        </section>

        <section className="section-white">
          <Container>
            <Grid cssClass="section-white__inner based-in" dataAttr="2/1">
              <div>
                <Heading heading={data?.["based-in"]?.heading} />
                <Para para={data?.["based-in"]?.para} />
              </div>
              {screenWidth <= 1080 ? (
                <img src={basedInSmall} alt="iceland flag" />
              ) : (
                <img src={basedIn} alt="iceland flag" />
              )}
            </Grid>
          </Container>
        </section>

        <section className="no-investor">
          <Container>
            <Grid cssClass="no-investor__inner" dataAttr="2/1">
              <div>
                <Heading heading={data?.["no-investor"]?.heading} />
                <Para para={data?.["no-investor"]?.para1} />
                <Para
                  para={data?.["no-investor"]?.para2}
                  link={{
                    path: links?.whitepaper,
                    text: data?.["link-texts"]?.whitepaper,
                  }}
                />
              </div>
            </Grid>
          </Container>
        </section>

        <section className="section-lavender">
          <Container>
            <Grid cssClass="section-lavender__inner" dataAttr="3/1">
              <div>
                <Heading heading={data?.["stance-on-ai"]?.heading} />
                <Para para={data?.["stance-on-ai"]?.para1} />
                <Para para={data?.["stance-on-ai"]?.para2} />
              </div>
              <AIIcon />
            </Grid>
          </Container>
        </section>

        <section className="section-purple">
          <Container>
            <Grid cssClass="section-purple__inner coming-soon" dataAttr="3/1">
              <div>
                <Heading heading={data?.["coming-soon"]?.heading} />
                <Para
                  para={data?.["coming-soon"]?.para1}
                  link={{
                    path: "#join-waitlist",
                    text: data?.["link-texts"]?.["join-waitlist"],
                  }}
                  isAnchorLink={true}
                />
                <Para para={data?.["coming-soon"]?.para2} />
                <div className="coming-soon__links">
                  <LinkTo
                    link={{
                      path: links?.about,
                      text: data?.["link-texts"]?.about,
                    }}
                  />
                  <LinkTo
                    link={{
                      path: links?.whitepaper,
                      text: data?.["link-texts"]?.["whitepaper-text"],
                    }}
                  />
                  <LinkTo
                    link={{
                      path: links?.["third-generation"],
                      text: data?.["link-texts"]?.["third-generation"],
                    }}
                  />
                  <LinkTo
                    link={{
                      path: links?.contact,
                      text: data?.["link-texts"]?.contact,
                    }}
                  />
                </div>
              </div>
              <SonataLogoHash width={130} height={130} />
            </Grid>
          </Container>
        </section>

        <section className="section-white" id="join-waitlist">
          <Container>
            <Grid cssClass="section-white__inner join-waitlist" dataAttr="3/1">
              <div>
                <Heading heading={data?.["join-waitlist"]?.heading} />
                <Para para={data?.["join-waitlist"]?.para} />
                <WaitListModal show={true} isHome={true} />
              </div>
              <img src={joinWaitlist} alt="join waitlist" />
            </Grid>
          </Container>
        </section>

        <section className="discord">
          <div className="discord__wrapper">
            <Container>
              <div className="discord__join">
                <a href={discordLink} target="_blank">
                  {data?.discord?.join}
                  <DiscordSmall />
                </a>
              </div>
            </Container>
            {screenWidth >= 1080 && <DiscordLarge color="#7285f4" />}
          </div>
        </section>
      </div>
    </>
  )
}

export default Home
