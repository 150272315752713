const LinkPreviewIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2449 22.0229C23.405 22.8105 22.2917 23.2405 21.1404 23.222C19.9892 23.2035 18.8903 22.7379 18.0761 21.9238C17.262 21.1096 16.7964 20.0107 16.7779 18.8595C16.7594 17.7083 17.1894 16.595 17.977 15.7551L21.8948 11.8356C22.6859 11.0454 23.7463 10.5833 24.8636 10.5418C25.9809 10.5002 27.0726 10.8822 27.9202 11.6113M27.3805 6.35157C27.7895 5.92856 28.2786 5.59121 28.8193 5.35919C29.36 5.12716 29.9415 5.00512 30.5299 5.00016C31.1183 4.9952 31.7017 5.10743 32.2463 5.33031C32.7908 5.55319 33.2856 5.88225 33.7016 6.29831C34.1177 6.71436 34.4467 7.20909 34.6696 7.75364C34.8925 8.29819 35.0047 8.88167 34.9998 9.47004C34.9948 10.0584 34.8728 10.6399 34.6407 11.1806C34.4087 11.7214 34.0714 12.2105 33.6484 12.6195L29.7305 16.539C28.9394 17.3291 27.8791 17.7912 26.7618 17.8327C25.6445 17.8743 24.5527 17.4923 23.7051 16.7632"
      stroke="#FD5201"
      strokeWidth={3.48769}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9472 21.6627C34.9472 27.9256 34.9472 31.0579 33.0008 33.0027C31.0543 34.9475 27.9237 34.9492 21.6608 34.9492H18.3392C12.0763 34.9492 8.944 34.9492 6.9992 33.0027C5.0544 31.0563 5.05273 27.9256 5.05273 21.6627V18.3411C5.05273 12.0782 5.05273 8.94596 6.9992 7.00115C8.94566 5.05635 12.0763 5.05469 18.3392 5.05469"
      stroke="#3C0061"
      strokeWidth={3.48769}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default LinkPreviewIcon
