const FlagIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4644 8.30909L26.8722 17.8003L11.4644 25.7096V8.30909ZM30.9108 16.5639L11.1712 4.40427L11.1713 4.404L10.7093 4.11977L10.7091 4.1196L10.2249 3.82135L10.1708 3.91398C10.0877 3.89906 10.0031 3.89094 9.91794 3.88988C9.63805 3.88639 9.36311 3.95927 9.12136 4.09993C8.87973 4.24051 8.68058 4.44331 8.54279 4.68615C8.49891 4.76348 8.4617 4.84407 8.43137 4.927H8.33312V5.48201C8.33312 5.48219 8.33312 5.48238 8.33312 5.48256C8.33312 5.4826 8.33312 5.48264 8.33312 5.48267L8.33312 27.7417V28.2972V28.8528V35.5564V36.112H8.88867H10.9089H11.4644V35.5564V29.2774L30.3026 19.6071L30.3027 19.6075L30.8069 19.3483L30.8072 19.3481C30.8074 19.348 30.8077 19.3478 30.8079 19.3477L31.2912 19.0996L31.2479 19.0108C31.312 18.9406 31.3699 18.8645 31.4207 18.7834C31.5709 18.5439 31.655 18.2686 31.6654 17.9862C31.6757 17.7039 31.6121 17.4231 31.4801 17.1732L31.0517 17.3994L31.4801 17.1732C31.4352 17.0882 31.3829 17.0077 31.324 16.9327L31.373 16.8486L30.9121 16.5647C30.9119 16.5646 30.9118 16.5645 30.9116 16.5644C30.9115 16.5643 30.9113 16.5642 30.9112 16.5641L30.9108 16.5639Z"
      fill="#C80000"
      stroke="#C80000"
      strokeWidth={1.11111}
    />
  </svg>
)
export default FlagIcon
