const VerifiedNetworkIcon = () => {
  return (
    <svg
      width={200}
      height={201}
      viewBox="0 0 200 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8464 17.1719H154.18C170.013 17.1719 183.346 30.5052 183.346 46.3385V133.839L133.346 183.839H45.8464C30.013 183.839 16.6797 170.505 16.6797 154.672V46.3385C16.6797 30.5052 30.013 17.1719 45.8464 17.1719ZM154.18 125.505H166.68H167.513V48.8385C167.513 40.5052 160.846 33.8385 152.513 33.8385H48.3464C40.013 33.8385 33.3464 40.5052 33.3464 48.8385V152.172C33.3464 160.505 40.013 167.172 48.3464 167.172H125.013V154.672C125.013 138.839 138.346 125.505 154.18 125.505Z"
        fill="#3C0061"
      />
      <path
        d="M126.68 68.8385L139.18 81.3385L89.1797 131.339L60.013 102.172L72.513 89.6719L89.1797 106.339L126.68 68.8385Z"
        fill="#FD5201"
      />
    </svg>
  )
}

export default VerifiedNetworkIcon