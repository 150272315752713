const DraftIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.2967 14.6904L38.6533 17.0471L25.69 30.0104L23.33 30.0071L23.3333 27.6537L36.2967 14.6904Z"
      fill="#FD5201"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6667 20.0104V23.3437H13.3333V20.0104H21.6667ZM26.6667 13.3437V16.6771H13.3333V13.3437H26.6667Z"
      fill="#FD5201"
    />
    <path
      d="M33.3333 3.34375C33.7754 3.34375 34.1993 3.51934 34.5118 3.83191C34.8244 4.14447 35 4.56839 35 5.01042V11.2721L31.6667 14.6054V6.67708H8.33333V33.3437H31.6667V28.7471L35 25.4137V35.0104C35 35.4524 34.8244 35.8764 34.5118 36.1889C34.1993 36.5015 33.7754 36.6771 33.3333 36.6771H6.66667C6.22464 36.6771 5.80072 36.5015 5.48816 36.1889C5.17559 35.8764 5 35.4524 5 35.0104V5.01042C5 4.56839 5.17559 4.14447 5.48816 3.83191C5.80072 3.51934 6.22464 3.34375 6.66667 3.34375H33.3333Z"
      fill="#3C0061"
    />
  </svg>
)
export default DraftIcon
