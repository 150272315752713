const AppealIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 36.8948H22.6924H2.5ZM20 36.8948V30.1641H5.19231V36.8948"
      fill="#3C0061"
    />
    <path
      d="M2.5 36.8948H22.6924M20 36.8948V30.1641H5.19231V36.8948"
      stroke="#3C0061"
      strokeWidth={3.57143}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.1202 3.81922L12.4682 13.4712C11.4168 14.5227 11.4168 16.2273 12.4682 17.2788L16.7326 21.5432C17.784 22.5946 19.4887 22.5946 20.5401 21.5432L30.1922 11.8911C31.2436 10.8397 31.2436 9.13504 30.1922 8.08362L25.9277 3.81921C24.8763 2.7678 23.1717 2.7678 22.1202 3.81922Z"
      stroke="#FD5201"
      strokeWidth={3.57143}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3848 16.7109L37.5002 28.8264"
      stroke="#FD5201"
      strokeWidth={3.57143}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default AppealIcon
