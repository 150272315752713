import React from "react"
import { Link } from "react-router-dom"

export const Grid = ({ cssClass = "", children, dataAttr = "3/1" }) => {
  return (
    <div
      className={`inner-container__grid ${cssClass}`}
      data-is-grid-layout={dataAttr}
    >
      {children}
    </div>
  )
}

export const Heading = ({ heading }) => {
  return (
    <h2
      className="home-page-heading"
      dangerouslySetInnerHTML={{
        __html: heading,
      }}
    />
  )
}

export const Para = ({
  para,
  dataAttr = null,
  link = null,
  icon = null,
  isAnchorLink = false,
}) => {
  const parts = para?.split("%link%")
  return (
    <p className="home-page-para" data-is-attr={dataAttr}>
      {parts?.map((part, index) => (
        <React.Fragment key={index}>
          {icon && icon}
          <span dangerouslySetInnerHTML={{ __html: part }} />
          {index !== parts?.length - 1 &&
            link &&
            (isAnchorLink ? (
              <a href={link?.path}>{link?.text}</a>
            ) : (
              <Link to={link?.path}>{link?.text}</Link>
            ))}
        </React.Fragment>
      ))}
    </p>
  )
}

export const LinkTo = ({ link }) => {
  return (
    <Link className="home-page-link" to={link?.path}>
      {link?.text}
    </Link>
  )
}

export const Container = ({ children }) => {
  return <div className="inner-container">{children}</div>
}

export const List = ({ text }) => {
  return (
    <ol className="home-page-para" data-is-attr={"list"}>
      {text?.map((el, i) => (
        <li key={i}>{el}</li>
      ))}
    </ol>
  )
}
