const AnnouncementIcon = (props) => (
  <svg
    width={44}
    height={40}
    viewBox="0 0 44 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10590_12651)">
      <path
        d="M22.2842 11.0674H7.36621C6.37709 11.0674 5.42848 11.443 4.72906 12.1116C4.02965 12.7801 3.63672 13.6869 3.63672 14.6324V21.7623C3.63672 22.7078 4.02965 23.6145 4.72906 24.2831C5.42848 24.9517 6.37709 25.3272 7.36621 25.3272H9.23096V32.4572C9.23096 32.9299 9.42742 33.3833 9.77713 33.7176C10.1268 34.0519 10.6011 34.2396 11.0957 34.2396H14.8252C15.3198 34.2396 15.7941 34.0519 16.1438 33.7176C16.4935 33.3833 16.6899 32.9299 16.6899 32.4572V25.3272H22.2842L31.6079 32.4572V3.9375L22.2842 11.0674ZM39.9993 18.1973C39.9993 21.2454 38.2091 24.0082 35.3374 25.3272V11.0674C38.1905 12.4043 39.9993 15.1671 39.9993 18.1973Z"
        fill="#FD5201"
      />
    </g>
    <defs>
      <clipPath id="clip0_10590_12651">
        <rect
          width={36.3636}
          height={32.7273}
          fill="white"
          transform="translate(3.63672 3.63281)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default AnnouncementIcon

