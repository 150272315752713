const ThreadIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44225 6.94665C8.72351 6.66538 9.10499 6.50737 9.50276 6.50737H30.4997C30.8974 6.50737 31.2789 6.66538 31.5602 6.94665C31.8414 7.22783 31.9994 7.60952 31.9995 8.00715L31.998 14.1349C31.998 14.5327 31.84 14.9142 31.5587 15.1954C31.2774 15.4767 30.896 15.6347 30.4982 15.6347H9.50129C9.10353 15.6347 8.72205 15.4767 8.44079 15.1954C8.15961 14.9143 8.00161 14.5326 8.00151 14.1349L8.00298 8.00715C8.00298 7.60938 8.16099 7.22791 8.44225 6.94665ZM9.50276 3.50781C8.30946 3.50781 7.16503 3.98185 6.32124 4.82564C5.47754 5.66934 5.00351 6.81398 5.00342 8.00715L5.00195 14.1349C5.00195 15.3282 5.47599 16.4727 6.31978 17.3164C7.16357 18.1602 8.30799 18.6343 9.50129 18.6343H30.4982C31.6915 18.6343 32.8359 18.1602 33.6797 17.3164C34.5234 16.4727 34.9975 15.3281 34.9975 14.1349L34.999 8.00715C34.999 6.81385 34.525 5.66943 33.6812 4.82564C32.8374 3.98185 31.693 3.50781 30.4997 3.50781H9.50276Z"
      fill="#3C0061"
    />
    <path
      d="M12.5023 9.50693C11.674 9.50693 11.0025 10.1784 11.0025 11.0067C11.0025 11.835 11.674 12.5065 12.5023 12.5065H27.5001C28.3284 12.5065 28.9999 11.835 28.9999 11.0067C28.9999 10.1784 28.3284 9.50693 27.5001 9.50693H12.5023Z"
      fill="#FD5201"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44225 24.9466C8.72351 24.6654 9.10499 24.5074 9.50276 24.5074H30.4997C30.8974 24.5074 31.2789 24.6654 31.5602 24.9466C31.8414 25.2278 31.9994 25.6095 31.9995 26.0072L31.998 32.1349C31.998 32.5327 31.84 32.9142 31.5587 33.1954C31.2774 33.4767 30.896 33.6347 30.4982 33.6347H9.50129C9.10353 33.6347 8.72205 33.4767 8.44079 33.1954C8.15961 32.9143 8.00161 32.5326 8.00151 32.1349L8.00298 26.0072C8.00298 25.6094 8.16099 25.2279 8.44225 24.9466ZM9.50276 21.5078C8.30946 21.5078 7.16503 21.9818 6.32124 22.8256C5.47754 23.6693 5.00351 24.814 5.00342 26.0072L5.00195 32.1349C5.00195 33.3282 5.47599 34.4727 6.31978 35.3164C7.16357 36.1602 8.30799 36.6343 9.50129 36.6343H30.4982C31.6915 36.6343 32.8359 36.1602 33.6797 35.3164C34.5234 34.4727 34.9975 33.3281 34.9975 32.1349L34.999 26.0072C34.999 24.8139 34.525 23.6694 33.6812 22.8256C32.8374 21.9818 31.693 21.5078 30.4997 21.5078H9.50276Z"
      fill="#3C0061"
    />
    <path
      d="M12.5023 27.5069C11.674 27.5069 11.0025 28.1784 11.0025 29.0067C11.0025 29.835 11.674 30.5065 12.5023 30.5065H27.5001C28.3284 30.5065 28.9999 29.835 28.9999 29.0067C28.9999 28.1784 28.3284 27.5069 27.5001 27.5069H12.5023Z"
      fill="#FD5201"
    />
  </svg>
)
export default ThreadIcon
