const NoteHistory = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10590_12689)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.704 13.6749L10.704 9.74931C10.704 8.83442 11.0675 7.957 11.7144 7.31008C12.3613 6.66316 13.2387 6.29972 14.1536 6.29972L22.2026 6.29972V10.8992C22.2026 12.7289 22.9295 14.4838 24.2233 15.7776C25.5172 17.0715 27.272 17.7983 29.1018 17.7983H33.7012V25.8474C33.7012 26.7622 33.3378 27.6397 32.6909 28.2866C32.044 28.9335 31.1665 29.2969 30.2517 29.2969H26.326C26.0984 30.0995 25.7948 30.8676 25.4222 31.5967H30.2517C31.7765 31.5967 33.2388 30.9909 34.317 29.9127C35.3952 28.8345 36.001 27.3722 36.001 25.8474V15.9907C36.0008 15.0765 35.6376 14.1997 34.9914 13.553L26.4479 5.01188C25.8016 4.36477 24.9248 4.00081 24.0102 4H14.1536C12.6288 4 11.1664 4.60573 10.0882 5.68393C9.01003 6.76214 8.4043 8.2245 8.4043 9.74931V14.5787C9.13933 14.2027 9.90962 13.8999 10.704 13.6749ZM33.3655 15.1766C33.4585 15.2702 33.5348 15.3791 33.5908 15.4986H29.1018C27.8819 15.4986 26.7121 15.014 25.8495 14.1515C24.9869 13.2889 24.5023 12.119 24.5023 10.8992V6.41011C24.621 6.46643 24.7291 6.54265 24.822 6.63548L33.3655 15.1766Z"
        fill="#3C0061"
      />
      <path
        d="M10.9084 13.8295L10.7597 13.8716C9.97825 14.093 9.22048 14.3908 8.4974 14.7607L8.19988 14.9129V14.5787V9.74931C8.19988 8.17028 8.82714 6.65593 9.94368 5.53939C11.0602 4.42285 12.5746 3.79558 14.1536 3.79558H24.0102H24.0104C24.9791 3.79643 25.9078 4.18193 26.5924 4.86731L10.9084 13.8295ZM10.9084 13.8295V13.6749L10.9084 9.74931C10.9084 8.88863 11.2503 8.06321 11.8589 7.45463C12.4675 6.84604 13.2929 6.50414 14.1536 6.50414L21.9982 6.50414M10.9084 13.8295L21.9982 6.50414M21.9982 6.50414V10.8992C21.9982 12.7832 22.7466 14.59 24.0788 15.9222C25.411 17.2543 27.2178 18.0027 29.1018 18.0027H33.4968V25.8474C33.4968 26.708 33.1549 27.5334 32.5463 28.142C31.9377 28.7506 31.1123 29.0925 30.2517 29.0925H26.326H26.1715L26.1294 29.2412C25.9054 30.0308 25.6067 30.7864 25.2402 31.5036L25.0882 31.8011H25.4222H30.2517C31.8307 31.8011 33.345 31.1738 34.4616 30.0573C35.5781 28.9407 36.2054 27.4264 36.2054 25.8474V15.9907V15.9907C36.2052 15.0223 35.8205 14.0935 35.136 13.4085L35.1359 13.4085L26.5925 4.86742L21.9982 6.50414ZM24.7068 10.8992V6.80933L33.1939 15.2942H29.1018C27.9362 15.2942 26.8183 14.8311 25.994 14.0069C25.1698 13.1827 24.7068 12.0648 24.7068 10.8992Z"
        stroke="#3C0061"
        strokeWidth={0.408839}
      />
      <path
        d="M7.73599 19.1525V17.0299V16.9299H7.63599H5.5918H5.4918V17.0299V23.1625V23.2625H5.5918H11.7244H11.8244V23.1625V21.1183V21.0183H11.7244H9.11376C9.78131 20.1517 10.5904 19.4652 11.5415 18.9579C12.5488 18.4207 13.6314 18.152 14.7906 18.152C16.7591 18.1534 18.4244 18.8375 19.7926 20.2058C21.1609 21.574 21.8447 23.239 21.8454 25.2067C21.846 27.1744 21.1623 28.8397 19.7927 30.2086C18.423 31.5776 16.7577 32.2614 14.7907 32.2614C13.0265 32.2614 11.487 31.6907 10.1678 30.5482C8.84883 29.406 8.07258 27.9632 7.83731 26.2155L7.82564 26.1288H7.7382H5.6429H5.53133L5.5435 26.2397C5.80239 28.5986 6.81499 30.5678 8.57923 32.1426C10.3434 33.7174 12.4154 34.5056 14.7906 34.5056C14.7906 34.5056 14.7906 34.5056 14.7907 34.5056V34.4056C16.0697 34.4069 17.2665 34.1644 18.3813 33.6778C19.4961 33.1913 20.4671 32.5355 21.2943 31.7103C22.1215 30.8851 22.7774 29.9141 23.2618 28.7973C23.7463 27.6805 23.9889 26.4836 23.9896 25.2067C23.9902 23.9298 23.7477 22.7329 23.2618 21.6161C22.776 20.4993 22.1201 19.5283 21.2943 18.7031C20.4684 17.8779 19.4974 17.2224 18.3813 16.7366C17.2652 16.2507 16.0683 16.0078 14.7907 16.0078C13.3945 16.0078 12.0702 16.3059 10.8178 16.9021C9.61303 17.4757 8.58576 18.2778 7.73599 19.3085V19.1525ZM17.5818 29.5702L17.6526 29.6409L17.7233 29.5702L19.1542 28.1393L19.2249 28.0686L19.1542 27.9979L15.9128 24.7564V20.0962V19.9962H15.8128H13.7686H13.6686V20.0962V25.6155V25.657L13.6979 25.6862L17.5818 29.5702Z"
        fill="#FD5201"
        stroke="#FD5201"
        strokeWidth={0.2}
      />
    </g>
    <defs>
      <clipPath id="clip0_10590_12689">
        <rect
          width={32.7273}
          height={32.7273}
          fill="white"
          transform="translate(3.63672 3.63281)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default NoteHistory
