export const AndroidIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 40}
    height={props?.height ? props?.height : 40}
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fill="#A4C439"
      d="M6.673 13.49a2.276 2.276 0 0 0-2.276 2.277l.002 9.528a2.275 2.275 0 1 0 4.55 0v-9.53a2.277 2.277 0 0 0-2.276-2.274Zm18.35-8.556 1.587-2.9a.317.317 0 0 0-.124-.43.317.317 0 0 0-.428.126l-1.605 2.93c-1.35-.6-2.86-.937-4.458-.935a10.916 10.916 0 0 0-4.448.931L13.94 1.733a.314.314 0 0 0-.522-.047.31.31 0 0 0-.03.349l1.589 2.898c-3.122 1.61-5.231 4.676-5.23 8.196l20.497-.002c.001-3.52-2.105-6.579-5.222-8.193Zm-9.69 4.482a.86.86 0 1 1 .001-1.72.86.86 0 0 1-.001 1.72Zm9.333-.002a.859.859 0 1 1-.001-1.717.859.859 0 0 1 0 1.717ZM9.83 13.921l.004 14.767a2.425 2.425 0 0 0 2.428 2.427h1.653l.002 5.043a2.277 2.277 0 0 0 2.274 2.276 2.278 2.278 0 0 0 2.276-2.278l-.001-5.04 3.072-.001v5.041a2.276 2.276 0 0 0 4.552-.002l-.002-5.04 1.66-.001a2.428 2.428 0 0 0 2.429-2.429l-.003-14.767-20.344.004Zm25.772 1.842a2.275 2.275 0 1 0-4.55.001l.002 9.529a2.275 2.275 0 1 0 4.55-.002l-.002-9.528Z"
    />
  </svg>
)

export const AppleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 34}
    height={props?.height ? props?.height : 40}
    viewBox="0 0 34 40"
    fill="none"
  >
    <path
      fill="#000"
      d="M28.088 38.248c-2.15 2.094-4.498 1.763-6.758.771-2.391-1.014-4.586-1.058-7.109 0-3.16 1.367-4.827.97-6.714-.771C-3.201 27.16-1.621 10.277 10.535 9.66c2.962.154 5.024 1.631 6.758 1.763 2.59-.529 5.069-2.05 7.833-1.851 3.313.264 5.815 1.587 7.46 3.967-6.845 4.122-5.222 13.181 1.054 15.716-1.25 3.306-2.875 6.59-5.573 9.015l.021-.022ZM17.073 9.528C16.744 4.612 20.716.557 25.28.16c.636 5.687-5.135 9.919-8.207 9.368Z"
    />
  </svg>
)

export const WebsiteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ? props?.width : 40}
    height={props?.height ? props?.height : 40}
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fill="#0081E6"
      d="M36.537 20c0-9.133-7.404-16.537-16.537-16.537S3.467 10.867 3.467 20c0 8.574 6.525 15.624 14.882 16.454v-3.047c-1.287-1.012-2.444-3.15-3.206-5.969h3.207V24.96h-3.745a31.36 31.36 0 0 1-.223-8.267h11.233A31.36 31.36 0 0 1 25.789 20h2.48c0-1.13-.055-2.237-.158-3.307h5.552c.255 1.058.392 2.166.392 3.307H36.537ZM8.073 27.44h4.5l.169.653c.524 1.934 1.237 3.63 2.1 4.985a14.107 14.107 0 0 1-6.769-5.638ZM6.337 16.692h5.552a34.476 34.476 0 0 0-.157 3.306c0 1.725.124 3.391.359 4.96H6.845a14.024 14.024 0 0 1-.9-4.96 14.1 14.1 0 0 1 .384-3.278l.008-.027v-.001Zm8.468-9.758.038-.014c-1.193 1.872-2.1 4.395-2.617 7.292h-5.04a14.104 14.104 0 0 1 7.62-7.278Zm5.003-.982L20 5.943l.209.01c2.086.209 4.1 3.514 5.034 8.259H14.759c.94-4.759 2.96-8.069 5.051-8.26h-.002Zm5.527 1.256-.177-.288.482.2a14.108 14.108 0 0 1 7.173 7.093h-5.039c-.493-2.752-1.332-5.166-2.439-7.005ZM20 31.572a1.653 1.653 0 0 0 1.654 1.653h4.96v3.307h-.827a.827.827 0 0 0 0 1.653H32.4a.827.827 0 0 0 0-1.653h-.826v-3.307h4.96a1.653 1.653 0 0 0 1.653-1.653v-8.267a1.653 1.653 0 0 0-1.654-1.653h-14.88A1.653 1.653 0 0 0 20 23.305v8.267Z"
    />
  </svg>
)
