const ChannelIcon = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9993 15.2218L11.9993 34.7636C11.9993 35.6578 12.3138 36.4195 12.9429 37.0486C13.5733 37.6777 14.335 37.9922 15.2279 37.9922H29.0632C29.1162 37.9922 29.1671 37.9711 29.2046 37.9336L37.9297 29.2085C37.9672 29.171 37.9883 29.1201 37.9883 29.0671V15.2318C37.9883 14.3375 37.6737 13.5759 37.0447 12.9468C36.4143 12.3177 35.6526 12.0032 34.7596 12.0032L15.2399 12.0032C14.3456 12.0032 13.582 12.3164 12.9489 12.9428C12.3158 13.5665 11.9993 14.3262 11.9993 15.2218ZM29.6078 27.9964H35.5063C35.6845 27.9964 35.7737 28.2118 35.6477 28.3378L28.3339 35.6516C28.2079 35.7776 27.9925 35.6884 27.9925 35.5102V29.6117C27.9925 29.1479 28.1458 28.7628 28.4523 28.4562C28.7589 28.1497 29.144 27.9964 29.6078 27.9964Z"
      fill="#3C0061"
    />
    <path
      d="M2.0535 9.19639C1.88024 8.3021 2.05217 7.49711 2.56929 6.78141C3.0864 6.06438 3.79277 5.61857 4.68839 5.44398L23.9182 2.05741C24.8112 1.88415 25.6162 2.05608 26.3332 2.57319C27.0502 3.09031 27.4954 3.79667 27.6687 4.69229L28.0537 7.00234C28.074 7.12424 27.98 7.23522 27.8564 7.23522L15.2279 7.23522C13.0289 7.23522 11.1463 8.01822 9.58032 9.58422C8.01431 11.1502 7.23131 13.0328 7.23131 15.2318L7.23131 30.467C7.23131 30.6149 7.07671 30.7105 6.95077 30.6331C6.61448 30.4263 6.32083 30.1611 6.0698 29.8376C5.74994 29.4271 5.54003 28.9567 5.44007 28.4262L2.0535 9.19639Z"
      fill="#FD5201"
    />
  </svg>
)
export default ChannelIcon
