const FederatedIcon = (props) => (
  <svg
    width={200}
    height={201}
    viewBox="0 0 200 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.9916 117.165C104.825 117.165 108.967 120.606 109.858 125.356L120.825 183.831H79.1583L90.1249 125.356C91.0166 120.606 95.1583 117.165 99.9916 117.165Z"
      fill="#3C0061"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0666 24.9062L52.8499 36.6896C46.6502 42.8736 41.7337 50.2218 38.3829 58.3119C35.0322 66.4021 33.3132 75.0747 33.3249 83.8312C33.3249 102.248 40.7833 118.915 52.8499 130.973L41.0749 142.748C33.3247 135.02 27.1779 125.837 22.9877 115.726C18.7975 105.615 16.6465 94.776 16.6583 83.8312C16.6443 72.8857 18.7935 62.0453 22.9823 51.933C27.1711 41.8207 33.3169 32.6359 41.0666 24.9062ZM158.917 24.9062C166.666 32.6359 172.812 41.8207 177.001 51.933C181.19 62.0453 183.339 72.8857 183.325 83.8312C183.338 94.7767 181.189 105.617 177 115.729C172.812 125.841 166.666 135.026 158.917 142.756L147.133 130.973C153.333 124.789 158.25 117.441 161.6 109.351C164.951 101.26 166.67 92.5878 166.658 83.8312C166.658 65.4146 159.2 48.7479 147.133 36.6896L158.917 24.9062ZM64.6333 48.4729L76.4249 60.2646C73.3246 63.3556 70.8658 67.0288 69.1897 71.0732C67.5136 75.1176 66.6533 79.4533 66.6583 83.8312C66.6583 93.0396 70.3916 101.373 76.4249 107.406L64.6333 119.19C59.9835 114.551 56.2963 109.04 53.7836 102.972C51.2708 96.9037 49.9822 90.3989 49.9916 83.8312C49.9916 70.0229 55.5916 57.5229 64.6333 48.4729ZM135.35 48.4729C140 53.1112 143.687 58.6228 146.2 64.6908C148.712 70.7588 150.001 77.2636 149.992 83.8312C150.001 90.3989 148.712 96.9037 146.2 102.972C143.687 109.04 140 114.551 135.35 119.19L123.558 107.398C126.658 104.307 129.117 100.633 130.793 96.589C132.469 92.5447 133.329 88.2091 133.325 83.8312C133.33 79.4533 132.47 75.1176 130.794 71.0732C129.117 67.0288 126.659 63.3556 123.558 60.2646L135.35 48.4729Z"
      fill="#FD5201"
    />
    <path
      d="M99.9916 100.498C95.5713 100.498 91.3321 98.742 88.2065 95.6164C85.0809 92.4908 83.3249 88.2515 83.3249 83.8312C83.3249 79.411 85.0809 75.1717 88.2065 72.0461C91.3321 68.9205 95.5713 67.1646 99.9916 67.1646C104.412 67.1646 108.651 68.9205 111.777 72.0461C114.902 75.1717 116.658 79.411 116.658 83.8312C116.658 88.2515 114.902 92.4908 111.777 95.6164C108.651 98.742 104.412 100.498 99.9916 100.498Z"
      fill="#3C0061"
    />
  </svg>
)
export default FederatedIcon
